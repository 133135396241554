import React from 'react';

import 'Components/Grid/Grid.scss';

const Grid = ({
  className = '', center = false, reverse = false, children,
}) => (
  <div
    className={`
      Grid
      ${center ? 'Grid--Center' : ''}
      ${reverse ? 'Grid--Reverse' : ''}
      / ${className}
    `}
  >
    {children}
  </div>
);

const GridCell = ({
  className = '', size = '', offset = '', children,
}) => (
  <div
    className={`
      Grid-Cell
      ${size ? `Grid-Cell--Size(${size})` : ''}
      ${offset ? `Grid-Cell--Offset(${offset})` : ''}
      / ${className}
    `}
  >
    {children}
  </div>
);

export {
  Grid,
  GridCell,
};
