import { useState, useEffect, useCallback } from 'react';

const useForm = (stateSchema, validationSchema = {}, callback) => {
  const [state, setState] = useState(stateSchema);
  const [disabled, setDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive
  // memory leaked in every re-render in component.
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      const isInputFieldRequired = validationSchema[key].required;
      const stateValue = state[key].value;
      const stateError = state[key].error;

      return (isInputFieldRequired && !stateValue) || stateError;
    });

    return hasErrorInState;
  }, [state, validationSchema]);

  // Used to handle every changes in every input.
  const handleChange = useCallback(
    (event) => {
      setIsDirty(true);

      const { name } = event.target;
      const { value } = event.target;

      let error = '';

      if (validationSchema[name].required) {
        if (!value) {
          error = 'This filed is required.';
        }
      }

      if (
        validationSchema[name].validator !== null
        && typeof validationSchema[name].validator === 'object'
      ) {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
          error = validationSchema[name].validator.error;
        }
      }

      setState((prevState) => ({
        ...prevState,
        [name]: { value, error },
      }));
    },
    [validationSchema],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      // Make sure that validateState returns false
      // before calling the submit callback function.
      if (!validateState()) {
        callback(state);
      }
    },
    [callback, state, validateState],
  );

  // Disable button in initial render.
  useEffect(() => {
    setDisabled(true);
  }, []);

  // For every changed in our state this will be fired
  // to be able to disable the button.
  useEffect(() => {
    if (isDirty) {
      setDisabled(validateState());
    }
  }, [state, isDirty, validateState]);

  return {
    state, disabled, handleChange, handleSubmit,
  };
};

export default useForm;
