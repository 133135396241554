import React from 'react';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Button from 'Components/Button/Button';
import Anchor from 'Components/Anchor/Anchor';

import 'Pages/About/About.scss';

import ResumePdf from 'Assets/Documents/Resume-FabianPombo.pdf';

const About = () => (
  <div className="About">
    <div className="About-Me">
      <Grid>
        <GridCell size="4/8">
          <div className="About-Intro">
            <h2 className="About-Title">
              About Me.
            </h2>
            <p className="About-Paragraph">
              I have been designing digital experiences for the last 5 years. Currently, as a User
              Experience Designer at CareCloud where my focus has been designing their next-generation
              healthcare platform, CareCloud Go, using a Lean UX methodology combined with rapid iteration
              and prototyping to deliver features.
            </p>
            <p className="About-Paragraph">
              As a User Experience Designer, I am passionate about immersing myself in all phases of product
              design and its execution. Ideating with Project Managers, translating findings to wireframes &
              high-fidelity prototypes, & collaborating with UI Developers to execute the vision adhering to
              the brand guidelines & design systems established.
            </p>
          </div>
        </GridCell>
      </Grid>
    </div>
    <div className="Resume">
      <div className="Resume-Entry">
        <Grid>
          <GridCell offset="1/8" size="2/8">
            <h2 className="Resume-Company">
              CareCloud
            </h2>
            <p className="Resume-Period">
              April 2018 - Present
            </p>
          </GridCell>
          <GridCell size="4/8">
            <h3 className="Resume-Position">
              UX Designer
            </h3>
            <p className="Resume-Overview">
              Responsible for designing the next-generation healthcare platform.
            </p>
            <ul className="Resume-Details">
              <li className="Resume-Detail">
                Collaborate with product managers & engineers to architect, roadmap & design
                product requirements for the application.
              </li>
              <li className="Resume-Detail">
                Gather qualitative data through weekly video conferences & in-person visits with
                current and prospective clients to iterate & validate workflows & features.
              </li>
              <li className="Resume-Detail">
                Prototype & present workflows to stakeholders.
              </li>
              <li className="Resume-Detail">
                Hand-off pixel-perfect mock-ups to engineers.
              </li>
              <li className="Resume-Detail">
                Responsible for designing the CareCloud design system & ensuring designers & developers
                adhere to the standards set.
              </li>
            </ul>
          </GridCell>
        </Grid>
      </div>
      <div className="Resume-Entry">
        <Grid>
          <GridCell offset="1/8" size="2/8">
            <p className="Resume-Period">
              September 2016 - April 2018
            </p>
          </GridCell>
          <GridCell size="4/8">
            <h3 className="Resume-Position">
              Web Designer
            </h3>
            <p className="Resume-Overview">
              Designed responsive experiences across customer-facing pages.
            </p>
            <ul className="Resume-Details">
              <li className="Resume-Detail">
                Redesigned <Anchor href="https://carecloud.com/pricing" blank>carecloud.com/pricing</Anchor> resulting
                in an 8% increase in organic lead generation.
              </li>
              <li className="Resume-Detail">
                Designed &
                developed <Anchor href="http://cloudup.carecloud.com" blank>cloudup.carecloud.com</Anchor> resulting in
                over 300 ticket sales for the company’s first-ever user summit in Miami, FL.
              </li>
              <li className="Resume-Detail">
                Collaborated with the VP of Marketing to design &
                launch <Anchor href="https://carecloud.com/continuum" blank>carecloud.com/continuum</Anchor> - a
                blog with over 2,500 subscribers.
              </li>
              <li className="Resume-Detail">
                Assisted in the branding for Breeze - CareCloud’s patient-facing mobile application with
                over 20,000 monthly active users.
              </li>
            </ul>
          </GridCell>
        </Grid>
      </div>
      <div className="Resume-Entry">
        <Grid>
          <GridCell offset="1/8" size="2/8">
            <h2 className="Resume-Company">
              Young & Rubicam
            </h2>
            <p className="Resume-Period">
              January 2015 - May 2015
            </p>
          </GridCell>
          <GridCell size="4/8">
            <h3 className="Resume-Position">
              Digital Studio Intern
            </h3>
            <ul className="Resume-Details">
              <li className="Resume-Detail">
                Designed B2B & B2C emails for Dell resulting in a .43% increase in click-through rate.
              </li>
              <li className="Resume-Detail">
                Collaborated with creative leads to design new direct mail collateral for Dell.
              </li>
              <li className="Resume-Detail">
                Responsible for cataloging all digital & print media created for the Dell account during
                the 2014 calendar year.
              </li>
            </ul>
          </GridCell>
        </Grid>
      </div>
      <div className="Resume-Entry">
        <Grid>
          <GridCell offset="1/8" size="2/8">
            <p className="Resume-Period">
              June 2014 - August 2014
            </p>
          </GridCell>
          <GridCell size="4/8">
            <h3 className="Resume-Position">
              Studio Intern
            </h3>
            <ul className="Resume-Details">
              <li className="Resume-Detail">
                Responsible for completing audits for Colgate & Palmolive websites to ensure component
                consistency & up-to-date image rights.
              </li>
              <li className="Resume-Detail">
                Assisted in the rebranding of the studio, including typographic guidelines, a new color
                palette & logo.
              </li>
            </ul>
          </GridCell>
        </Grid>
      </div>
      <div className="Resume-Download">
        <Button href={ResumePdf} blank>
          Download Resume
        </Button>
      </div>
    </div>
  </div>
);

export default About;
