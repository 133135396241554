import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toCapitalCase } from 'Utils/general.utils';

import { SECTION_NAME, SECTION_PATH } from 'Constants/global.constants';

import 'Components/Header/Header.scss';

const Header = ({ page }) => {
  const [isMenuOpen, setMenuAsOpen] = useState(false);

  return (
    <div
      className={`
        Header
        ${page === SECTION_NAME.HOME ? 'Header--Light' : ''}
        ${isMenuOpen ? 'Header--MenuOpen' : ''}
      `}
    >
      <h1 className="Header-Logo">
        <Link className="Header-Link" to={SECTION_PATH.HOME}>Fabian Pombo</Link>
      </h1>
      <nav className={`Nav Nav--Section${toCapitalCase(page)}`}>
        <ul className="Nav-List">
          <li className="Nav-ListItem">
            <Link className="Nav-Link Nav-Link--SectionWork" to={SECTION_PATH.WORK}>Work</Link>
          </li>
          <li className="Nav-ListItem">
            <Link className="Nav-Link Nav-Link--SectionAbout" to={SECTION_PATH.ABOUT}>About</Link>
          </li>
          <li className="Nav-ListItem">
            <Link className="Nav-Link Nav-Link--SectionContact" to={SECTION_PATH.CONTACT}>Contact</Link>
          </li>
        </ul>
      </nav>
      <nav className="Menu">
        <div className="Menu-Cover" />
        <div
          className="Menu-Hamburger"
          onClick={() => setMenuAsOpen(!isMenuOpen)}
          onKeyPress={() => setMenuAsOpen(!isMenuOpen)}
          role="button"
          tabIndex={0}
        >
          <span className="Menu-HamburgerLine Menu-HamburgerLine--1" />
          <span className="Menu-HamburgerLine Menu-HamburgerLine--2" />
          <span className="Menu-HamburgerLine Menu-HamburgerLine--3" />
        </div>
        <ul className="Menu-List">
          <li className="Menu-ListItem">
            <Link className="Menu-Link" to={SECTION_PATH.WORK}>Work</Link>
          </li>
          <li className="Menu-ListItem">
            <Link className="Menu-Link" to={SECTION_PATH.ABOUT}>About</Link>
          </li>
          <li className="Menu-ListItem">
            <Link className="Menu-Link" to={SECTION_PATH.CONTACT}>Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
