import React from 'react';
import { Link } from 'react-router-dom';

import 'Components/Button/Button.scss';

const Button = ({
  className = '', href = '', blank = false, type = '',
  disabled = false, children, colorText = '', colorBorder = '',
}) => {
  // eslint-disable-next-line no-nested-ternary
  const Component = (href) ? ((blank) ? 'a' : Link) : 'button';

  const styleButton = { ...(colorText && { color: colorText }) };
  const styleBorderTop = { ...(colorBorder && { background: colorBorder }) };
  const styleBorderBottom = { ...(colorText && { background: colorText }) };

  return (
    <Component
      className={`Button / ${className}`}
      to={href || null}
      href={href || null}
      target={blank ? '_blank' : null}
      rel={blank ? 'noopener noreferrer' : null}
      type={type || null}
      disabled={disabled || null}
      style={styleButton}
    >
      {children}
      <span className="Button-Border Button-Border--Top" style={styleBorderTop} />
      <span className="Button-Border Button-Border--Bottom" style={styleBorderBottom} />
    </Component>
  );
};

export default Button;
