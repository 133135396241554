import React from 'react';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Image from 'Components/Image/Image';

import 'Pages/Project/Project.scss';

import Splash1x from 'Assets/Images/Testd/Splash.png';
import Splash2x from 'Assets/Images/Testd/Splash@2x.png';
import Splash3x from 'Assets/Images/Testd/Splash@3x.png';

import FigureFigma1x from 'Assets/Images/Testd/FigureFigma.png';
import FigureFigma2x from 'Assets/Images/Testd/FigureFigma@2x.png';
import FigureFigma3x from 'Assets/Images/Testd/FigureFigma@3x.png';
import FigureEditor1x from 'Assets/Images/Testd/FigureEditor.png';
import FigureEditor2x from 'Assets/Images/Testd/FigureEditor@2x.png';
import FigureEditor3x from 'Assets/Images/Testd/FigureEditor@3x.png';

import ToolFigma1x from 'Assets/Images/Common/Figma.png';
import ToolFigma2x from 'Assets/Images/Common/Figma@2x.png';
import ToolFigma3x from 'Assets/Images/Common/Figma@3x.png';
import ToolZeplin1x from 'Assets/Images/Common/Zeplin.png';
import ToolZeplin2x from 'Assets/Images/Common/Zeplin@2x.png';
import ToolZeplin3x from 'Assets/Images/Common/Zeplin@3x.png';
import ToolStorybook1x from 'Assets/Images/Common/Storybook.png';
import ToolStorybook2x from 'Assets/Images/Common/Storybook@2x.png';
import ToolStorybook3x from 'Assets/Images/Common/Storybook@3x.png';
import ToolRapidPrototyping1x from 'Assets/Images/Testd/RapidPrototyping.png';
import ToolRapidPrototyping2x from 'Assets/Images/Testd/RapidPrototyping@2x.png';
import ToolRapidPrototyping3x from 'Assets/Images/Testd/RapidPrototyping@3x.png';
import ToolUserInterviews1x from 'Assets/Images/Testd/UserInterviews.png';
import ToolUserInterviews2x from 'Assets/Images/Testd/UserInterviews@2x.png';
import ToolUserInterviews3x from 'Assets/Images/Testd/UserInterviews@3x.png';

const Project = () => (
  <div className="Project Project--NameTestd">
    <Grid>
      <GridCell size="6/8" offset="1/8">
        <div className="Project-Intro">
          <Grid>
            <GridCell>
              <div className="Project-Picture" data-aos="zoom-in-up">
                <Image
                  width="888"
                  height="555"
                  src={Splash1x}
                  srcSet={`${Splash2x} 2x, ${Splash3x} 3x`}
                  smooth
                />
              </div>
            </GridCell>
            <GridCell size="6/8" offset="1/8">
              <h2 className="Project-Title">
                testd
              </h2>
              <p className="Project-SubTitle">
                Taking a vision from concept to life.
              </p>
            </GridCell>
          </Grid>
        </div>
        <div className="Project-Details">
          <section className="Project-Section">
            <h3 className="Project-Heading">
              About testd
            </h3>
            <p className="Project-Paragraph">
              Testd is a test case management application designed to empower development
              teams to streamline their workflows with an intuitive and beautiful user interface.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              The Challenge
            </h3>
            <p className="Project-Paragraph">
              Starting from a blank canvas is both an exciting opportunity with limitless possibilities
              and an immense responsibility when tasked to execute a vision from another person’s mind.
              The challenge, for me then, was to immerse myself in a completely new field. Put myself in
              the shoes of a QA engineer and empathize with their frustrations to provide a more joyful
              experience to a heavily manual process. Luckily, I had the help of a former quality assurance
              tester along the way.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              My Role
            </h3>
            <p className="Project-Paragraph">
              I was the sole UX designer working with a small team consisting of 2 developers and one 1
              product manager. Responsible for establishing and executing the design direction of the entire
              project including the branding, architecture and user interface.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Tools Used
            </h3>
            <ul className="Project-Tools">
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="54"
                    height="80"
                    src={ToolFigma1x}
                    srcSet={`${ToolFigma2x} 2x, ${ToolFigma3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Figma
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="90"
                    height="72"
                    src={ToolZeplin1x}
                    srcSet={`${ToolZeplin2x} 2x, ${ToolZeplin3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Zeplin
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="144"
                    height="75"
                    src={ToolRapidPrototyping1x}
                    srcSet={`${ToolRapidPrototyping2x} 2x, ${ToolRapidPrototyping3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Rapid Prototyping
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="56"
                    height="70"
                    src={ToolStorybook1x}
                    srcSet={`${ToolStorybook2x} 2x, ${ToolStorybook3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Storybook
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="96"
                    height="68"
                    src={ToolUserInterviews1x}
                    srcSet={`${ToolUserInterviews2x} 2x, ${ToolUserInterviews3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    User Interviews
                  </figcaption>
                </figure>
              </li>
            </ul>
            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="555"
                src={FigureFigma1x}
                srcSet={`${FigureFigma2x} 2x, ${FigureFigma3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Add Users prototype for testd.
              </figcaption>
            </figure>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Proposed Solutions
            </h3>
            <p className="Project-Paragraph">
              Through user interviews, we quickly realized the loss of progress/data was a significant
              pain point for users of test case management applications. Current solutions do not indicate
              presence to users working in particular test cases causing constant overriding. We realized
              to solve this problem our solution would focus on collaboration features.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Solving the Problems
            </h3>
            <p className="Project-Paragraph">
              Observing quality assurance testers interact with the prototypes we found they felt relieved
              when they saw presence avatars showing other users in the test cases. When seeing other user’s
              cursors in tests users immediately thought of new ways the application can be used we hadn’t
              envisioned, specifically for training other users.
            </p>
            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="555"
                src={FigureEditor1x}
                srcSet={`${FigureEditor2x} 2x, ${FigureEditor3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Test editor showing different users working together.
              </figcaption>
            </figure>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Challenges Faced
            </h3>
            <p className="Project-Paragraph">
              During ideation, we prototyped different ways of informing users someone was already working on
              a particular test. Using a modal to deny access proved to be infuriating, especially for admins
              of the application. Another solution proposed was to have a read-only state for test currently
              being worked on and while better than limiting access it was clear what users wanted was to be
              able to work together.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Outcomes
            </h3>
            <p className="Project-Paragraph">
              Although still in its development phase, testd’s collaboration features will decrease the time
              testers spend re-writing tests and increase the amount of features development teams can deliver.
              The ground-breaking collaboration will also be a key differentiator in the marketplace for test
              case management applications.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Lessons Learned
            </h3>
            <p className="Project-Paragraph">
              While on working on this project the team realized the importance of solving problems in a way
              to add value. Having potential users understand and interact with our ideas in the form of
              prototypes gave us insights into new problems we could solve we never intended to.
            </p>
          </section>
        </div>
      </GridCell>
    </Grid>
  </div>
);

export default Project;
