import React from 'react';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Image from 'Components/Image/Image';

import 'Pages/Project/Project.scss';

import Splash1x from 'Assets/Images/Simple/Splash.png';
import Splash2x from 'Assets/Images/Simple/Splash@2x.png';
import Splash3x from 'Assets/Images/Simple/Splash@3x.png';

import FigureMetrics1x from 'Assets/Images/Simple/FigureMetrics.png';
import FigureMetrics2x from 'Assets/Images/Simple/FigureMetrics@2x.png';
import FigureMetrics3x from 'Assets/Images/Simple/FigureMetrics@3x.png';
import FigureLists1x from 'Assets/Images/Simple/FigureLists.png';
import FigureLists2x from 'Assets/Images/Simple/FigureLists@2x.png';
import FigureLists3x from 'Assets/Images/Simple/FigureLists@3x.png';
import FigureDashboard1x from 'Assets/Images/Simple/FigureDashboard.png';
import FigureDashboard2x from 'Assets/Images/Simple/FigureDashboard@2x.png';
import FigureDashboard3x from 'Assets/Images/Simple/FigureDashboard@3x.png';
import FigureIndividual1x from 'Assets/Images/Simple/FigureIndividual.png';
import FigureIndividual2x from 'Assets/Images/Simple/FigureIndividual@2x.png';
import FigureIndividual3x from 'Assets/Images/Simple/FigureIndividual@3x.png';
import FigureMass1x from 'Assets/Images/Simple/FigureMass.png';
import FigureMass2x from 'Assets/Images/Simple/FigureMass@2x.png';
import FigureMass3x from 'Assets/Images/Simple/FigureMass@3x.png';
import FigureReminder1x from 'Assets/Images/Simple/FigureReminder.png';
import FigureReminder2x from 'Assets/Images/Simple/FigureReminder@2x.png';
import FigureReminder3x from 'Assets/Images/Simple/FigureReminder@3x.png';
import FigureFeedback1x from 'Assets/Images/Simple/FigureFeedback.png';
import FigureFeedback2x from 'Assets/Images/Simple/FigureFeedback@2x.png';
import FigureFeedback3x from 'Assets/Images/Simple/FigureFeedback@3x.png';
import FigureMobile1x from 'Assets/Images/Simple/FigureMobile.png';
import FigureMobile2x from 'Assets/Images/Simple/FigureMobile@2x.png';
import FigureMobile3x from 'Assets/Images/Simple/FigureMobile@3x.png';

const Project = () => (
  <div className="Project Project--NameSimple">
    <div className="Project-Intro">
      <Grid>
        <GridCell size="2/8" offset="3/8">
          <div className="Project-Picture" data-aos="zoom-in-up">
            <Image
              className="Util-MX(a)"
              width="174"
              height="130"
              src={Splash1x}
              srcSet={`${Splash2x} 2x, ${Splash3x} 3x`}
              fluid={false}
            />
          </div>
        </GridCell>
        <GridCell size="4/8" offset="2/8">
          <h2 className="Project-Title">
            simple<span className="Project-TitleWord">practice</span> Challenge
          </h2>
          <p className="Project-SubTitle">
            Identifying a billing-related ‘problem area’ in the application and improve it
            to add value to practitioners.
          </p>
        </GridCell>
      </Grid>
    </div>
    <div className="Project-Details">

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              The Problem
            </h3>
            <p className="Project-Paragraph">
              Although some information might be found in the application’s Reports section,
              there is a lack of visibility into the state of a practice’s financial health and
              possible revenue collecting opportunities.
            </p>
          </GridCell>
        </Grid>
      </section>

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              The Solution
            </h3>
            <p className="Project-Paragraph">
              Empower practitioners to be proactive in billing their clients by providing more
              meaningful and actionable information such as:
            </p>
            <ul className="Project-List">
              <li className="Project-ListItem">
                Financial metrics
              </li>
              <li className="Project-ListItem">
                Options to increase collections
              </li>
            </ul>
          </GridCell>
        </Grid>
      </section>

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              Some Background
            </h3>
            <p className="Project-Paragraph">
              For the challenge, I assumed a solo-practitioner would be collecting payments for
              their practice. Some of the things this user would have to consider while collecting
              and allocating payments would be:
            </p>
            <ul className="Project-List">
              <li className="Project-ListItem">
                Revenue collected
              </li>
              <li className="Project-ListItem">
                Past due and unpaid invoices
              </li>
              <li className="Project-ListItem">
                Current or upcoming appointments
              </li>
              <li className="Project-ListItem">
                Client relationships
              </li>
            </ul>
            <p className="Project-Paragraph">
              As healthcare moves more towards a self-pay model a huge pain point for practices
              everywhere is collecting more money. This is especially true for behavioral health
              - where insurance coverage is less common. Understanding how a practice is performing
              financially and presenting revenue collecting opportunities can be a powerful way to
              increase collections and time spent with clients.
            </p>
          </GridCell>
        </Grid>
      </section>

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              Some Assumptions
            </h3>
            <ul className="Project-List">
              <li className="Project-ListItem">
                Practitioners are handling their collections with limited time
              </li>
              <li className="Project-ListItem">
                Practitioners need guidance fulfilling a role they find might uncomfortable
              </li>
            </ul>
          </GridCell>
        </Grid>
      </section>

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              The User
            </h3>
            <ul className="Project-List">
              <li className="Project-ListItem">
                Small office space
              </li>
              <li className="Project-ListItem">
                Established professional (35-45)
              </li>
              <li className="Project-ListItem">
                More tech-willing than savvy
              </li>
              <li className="Project-ListItem">
                The Sole point of contact for clients
              </li>
            </ul>
            <p className="Project-Paragraph">
              While simplepractice serves a lot of roles I thought it would be ideal to solve
              for the solo therapist, now also businessperson running their entire operation.
              I think solving for this type of user further emphasizes the point of why you would
              choose simplepractice over another practice management solution, to grow your practice.
            </p>
          </GridCell>
        </Grid>
      </section>

      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              The Concept: <i>From Billing to Financial</i>
            </h3>
            <p className="Project-Paragraph">
              Currently, there is no easy way to identify opportunities to collect from clients.
              Even if one were to look at the Invoices or Unpaid Invoices report understanding the
              total amount a practice might be losing out on is unclear unless you download a csv/excel
              file and do some calculations. If you wanted to act, even more steps are added by having
              to navigate to individual clients.
            </p>
            <p className="Project-Paragraph">
              Changing the Billing section of the application to Financial would better represent
              what this area of the application should be all about – running a healthy practice.
            </p>
          </GridCell>
        </Grid>

        <div className="Util-MT(32) Util-MT(64)@M">
          <Grid center>
            <GridCell size="3/8" offset="1/8">
              <h4 className="Project-Heading Project-Heading--2">
                Insightful Metrics
              </h4>
              <p className="Project-Paragraph">
                This section of the application would serve as a financial dashboard where users are
                presented with the information they need to understand their current financial situation
                and identify where to act.
              </p>
            </GridCell>
            <GridCell size="3/8">
              <figure className="Project-Figure / Util-MT(0)@M" data-aos="zoom-in-up">
                <Image
                  className="Project-FigureImage / Util-MX(a)"
                  width="350"
                  height="156"
                  src={FigureMetrics1x}
                  srcSet={`${FigureMetrics2x} 2x, ${FigureMetrics3x} 3x`}
                  fluid={false}
                  scaled
                />
              </figure>
            </GridCell>
          </Grid>
        </div>

        <div className="Util-MT(32) Util-MT(64)@M">
          <Grid center reverse>
            <GridCell size="3/8" offset="1/8">
              <h4 className="Project-Heading Project-Heading--2">
                Actionable Lists
              </h4>
              <p className="Project-Paragraph">
                Each of the individual dashboard items would be a window into a list of items a practitioner
                can tackle individually or as a whole with the aid of mass actions.
              </p>
            </GridCell>
            <GridCell size="3/8">
              <figure className="Project-Figure / Util-MT(0)@M" data-aos="zoom-in-up">
                <Image
                  className="Project-FigureImage / Util-MX(a)"
                  width="340"
                  height="190"
                  src={FigureLists1x}
                  srcSet={`${FigureLists2x} 2x, ${FigureLists3x} 3x`}
                  fluid={false}
                  scaled
                />
              </figure>
            </GridCell>
          </Grid>
        </div>
      </section>


      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              Wireframes
            </h3>

            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="624"
                src={FigureDashboard1x}
                srcSet={`${FigureDashboard2x} 2x, ${FigureDashboard3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Financial Dashboard View.
              </figcaption>
            </figure>

            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="624"
                src={FigureIndividual1x}
                srcSet={`${FigureIndividual2x} 2x, ${FigureIndividual3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                List View - Individual Actions.
              </figcaption>
            </figure>

            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="624"
                src={FigureMass1x}
                srcSet={`${FigureMass2x} 2x, ${FigureMass3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                List View - Mass Actions.
              </figcaption>
            </figure>

            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="624"
                src={FigureReminder1x}
                srcSet={`${FigureReminder2x} 2x, ${FigureReminder3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Setting up an email reminder to be sent at a future date.
              </figcaption>
            </figure>

            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="888"
                height="624"
                src={FigureFeedback1x}
                srcSet={`${FigureFeedback2x} 2x, ${FigureFeedback3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Feedback for an email successfully scheduled.
              </figcaption>
            </figure>
          </GridCell>
        </Grid>

        <div className="Util-MT(48)">
          <Grid center reverse>
            <GridCell size="3/8" offset="1/8">
              <p className="Project-Paragraph">
                During the challenge I prioritized showcasing how I envisioned this new
                Billing dashboard to work on a desktop experience. While practitioners
                would surely appreciate having visibility into their practice’s financial
                health on the go, this type of workflow is more likely to be accomplished
                on the web where financial information is more easily digestible.
              </p>
            </GridCell>
            <GridCell size="3/8">
              <figure className="Project-Figure / Util-MT(0)@M" data-aos="zoom-in-up">
                <Image
                  className="Project-FigureImage / Util-MX(a)"
                  width="264"
                  height="571"
                  src={FigureMobile1x}
                  srcSet={`${FigureMobile2x} 2x, ${FigureMobile3x} 3x`}
                  fluid={false}
                  scaled
                  smooth
                />
              </figure>
            </GridCell>
          </Grid>
        </div>
      </section>


      <section className="Project-Section">
        <Grid>
          <GridCell size="6/8" offset="1/8">
            <h3 className="Project-Heading">
              Lessons Were Learned
            </h3>
            <p className="Project-Paragraph">
              As I tried to complete this challenge in a reasonable timeframe I quickly realized I underestimated
              how long this concept would take to develop.
            </p>
            <p className="Project-Paragraph">
              What initially seemed bulletproof in mind unraveled into redundancies with the reporting section of
              the application. Grand visions of visualizing data had to be descoped into simple text to prove a
              point. A mobile solution was mostly left to the imagination. And a bonus concept (yes, I also thought
              I would be able to deliver a bonus concept), of shifting the Client Billing Tab to focus on Goods &
              Services as opposed to events à la Shopify now remains in my brain - anxious to be let out.
            </p>
            <p className="Project-Paragraph">
              With more time and more insights I am sure I&apos;ve could done much better and much more. Still, I
              am happy with the progress I was able to make.
            </p>
            <p className="Project-Paragraph">
              Thank you for the opportunity.
            </p>
          </GridCell>
        </Grid>
      </section>
    </div>
  </div>
);

export default Project;
