import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Image from 'Components/Image/Image';

import { PROJECT_PATH } from 'Constants/global.constants';

import 'Pages/Work/Work.scss';

import GoThumb1x from 'Assets/Images/Go/Thumb.png';
import GoThumb2x from 'Assets/Images/Go/Thumb@2x.png';
import GoThumb3x from 'Assets/Images/Go/Thumb@3x.png';
import TestdThumb1x from 'Assets/Images/Testd/Thumb.png';
import TestdThumb2x from 'Assets/Images/Testd/Thumb@2x.png';
import TestdThumb3x from 'Assets/Images/Testd/Thumb@3x.png';
import BeverllyThumb1x from 'Assets/Images/Beverlly/Thumb.png';
import BeverllyThumb2x from 'Assets/Images/Beverlly/Thumb@2x.png';
import BeverllyThumb3x from 'Assets/Images/Beverlly/Thumb@3x.png';

const Work = () => (
  <div>
    <Grid>
      <GridCell size="4/8">
        <Link className="Work" to={PROJECT_PATH.GO}>
          <div className="Work-Picture">
            <Image
              className="Work-Image"
              width="576"
              height="324"
              src={GoThumb1x}
              srcSet={`${GoThumb2x} 2x, ${GoThumb3x} 3x`}
            />
          </div>
          <h2 className="Work-Title">
            CareCloud Go
          </h2>
        </Link>
      </GridCell>
      <GridCell className="Util-MT(64) Util-MT(0)@M" size="4/8">
        <Link className="Work" to={PROJECT_PATH.TESTD}>
          <div className="Work-Picture">
            <Image
              className="Work-Image"
              width="576"
              height="324"
              src={TestdThumb1x}
              srcSet={`${TestdThumb2x} 2x, ${TestdThumb3x} 3x`}
            />
          </div>
          <h2 className="Work-Title">
            testd
          </h2>
        </Link>
      </GridCell>
      <GridCell className="Util-MT(64)" size="4/8">
        <Link className="Work" to={PROJECT_PATH.BEVERLLY}>
          <div className="Work-Picture">
            <Image
              className="Work-Image"
              width="576"
              height="324"
              src={BeverllyThumb1x}
              srcSet={`${BeverllyThumb2x} 2x, ${BeverllyThumb3x} 3x`}
            />
          </div>
          <h2 className="Work-Title">
            Beverlly.com
          </h2>
        </Link>
      </GridCell>
    </Grid>
  </div>
);

export default Work;
