import React from 'react';

import 'Components/Image/Image.scss';

const Image = ({
  className = '', src, srcSet = '', width = '', height = '',
  alt = '', smooth = false, fluid = true, scaled = false,
}) => (
  <img
    className={`
      Image
      ${smooth ? 'Image--Smooth' : ''}
      ${fluid ? 'Image--Fluid' : ''}
      ${scaled ? 'Image--Scaled' : ''}
      / ${className}
    `}
    src={src}
    srcSet={srcSet || null}
    width={width || null}
    height={height || null}
    alt={alt}
  />
);

export default Image;
