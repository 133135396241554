import React from 'react';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Image from 'Components/Image/Image';

import 'Pages/Project/Project.scss';

import Splash1x from 'Assets/Images/Go/Splash.png';
import Splash2x from 'Assets/Images/Go/Splash@2x.png';
import Splash3x from 'Assets/Images/Go/Splash@3x.png';

import FigureSketch1x from 'Assets/Images/Go/FigureSketch.png';
import FigureSketch2x from 'Assets/Images/Go/FigureSketch@2x.png';
import FigureSketch3x from 'Assets/Images/Go/FigureSketch@3x.png';
import FigureEncounter1x from 'Assets/Images/Go/FigureEncounter.png';
import FigureEncounter2x from 'Assets/Images/Go/FigureEncounter@2x.png';
import FigureEncounter3x from 'Assets/Images/Go/FigureEncounter@3x.png';
import FigureSummary1x from 'Assets/Images/Go/FigureSummary.png';
import FigureSummary2x from 'Assets/Images/Go/FigureSummary@2x.png';
import FigureSummary3x from 'Assets/Images/Go/FigureSummary@3x.png';

import ToolSketch1x from 'Assets/Images/Common/Sketch.png';
import ToolSketch2x from 'Assets/Images/Common/Sketch@2x.png';
import ToolSketch3x from 'Assets/Images/Common/Sketch@3x.png';
import ToolInvision1x from 'Assets/Images/Common/Invision.png';
import ToolInvision2x from 'Assets/Images/Common/Invision@2x.png';
import ToolInvision3x from 'Assets/Images/Common/Invision@3x.png';
import ToolStorybook1x from 'Assets/Images/Common/Storybook.png';
import ToolStorybook2x from 'Assets/Images/Common/Storybook@2x.png';
import ToolStorybook3x from 'Assets/Images/Common/Storybook@3x.png';
import ToolRapidPrototyping1x from 'Assets/Images/Go/RapidPrototyping.png';
import ToolRapidPrototyping2x from 'Assets/Images/Go/RapidPrototyping@2x.png';
import ToolRapidPrototyping3x from 'Assets/Images/Go/RapidPrototyping@3x.png';
import ToolUserInterviews1x from 'Assets/Images/Go/UserInterviews.png';
import ToolUserInterviews2x from 'Assets/Images/Go/UserInterviews@2x.png';
import ToolUserInterviews3x from 'Assets/Images/Go/UserInterviews@3x.png';

const Project = () => (
  <div className="Project Project--NameGo">
    <Grid>
      <GridCell size="6/8" offset="1/8">
        <div className="Project-Intro">
          <Grid>
            <GridCell size="6/8" offset="1/8">
              <div className="Project-Picture" data-aos="zoom-in-up">
                <Image
                  width="581"
                  height="530"
                  src={Splash1x}
                  srcSet={`${Splash2x} 2x, ${Splash3x} 3x`}
                />
              </div>
            </GridCell>
            <GridCell size="6/8" offset="1/8">
              <h2 className="Project-Title">
                Go EHR
              </h2>
              <p className="Project-SubTitle">
                Designing an electronic health records solution for the modern physician.
              </p>
            </GridCell>
          </Grid>
        </div>
        <div className="Project-Details">
          <section className="Project-Section">
            <h3 className="Project-Heading">
              About CareCloud Go
            </h3>
            <p className="Project-Paragraph">
              CareCloud Go is a breakthrough platform that unifies all clinical, financial,
              administrative and patient experience data in one seamless system.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              The Challenge
            </h3>
            <p className="Project-Paragraph">
              As physicians in the United States have been forced to transition from paper and
              pen to electronic health records they are spending more time documenting than having
              meaningful interactions with their patients. The goal: Reduce time spent documenting
              and increase the valuable face-to-face interactions both patients and doctors value
              so much.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              My Role
            </h3>
            <p className="Project-Paragraph">
              I was the sole UX designer on an Agile team comprised of 9 developers, a product manager,
              a business analyst, a scrum master and 2 quality assurance engineers. I was responsible
              for the overall design direction of the project, while collaborating with the product
              manager, business analyst, current and prospective customers as well as outside consultants.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Tools Used
            </h3>
            <ul className="Project-Tools">
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="80"
                    height="73"
                    src={ToolSketch1x}
                    srcSet={`${ToolSketch2x} 2x, ${ToolSketch3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Sketch
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="64"
                    height="64"
                    src={ToolInvision1x}
                    srcSet={`${ToolInvision2x} 2x, ${ToolInvision3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Invision
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="144"
                    height="75"
                    src={ToolRapidPrototyping1x}
                    srcSet={`${ToolRapidPrototyping2x} 2x, ${ToolRapidPrototyping3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Rapid Prototyping
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="56"
                    height="70"
                    src={ToolStorybook1x}
                    srcSet={`${ToolStorybook2x} 2x, ${ToolStorybook3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Storybook
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="96"
                    height="68"
                    src={ToolUserInterviews1x}
                    srcSet={`${ToolUserInterviews2x} 2x, ${ToolUserInterviews3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    User Interviews
                  </figcaption>
                </figure>
              </li>
            </ul>
            <figure className="Project-Figure" data-aos="zoom-in-up">
              <Image
                className="Project-FigureImage"
                width="420"
                height="315"
                src={FigureSketch1x}
                srcSet={`${FigureSketch2x} 2x, ${FigureSketch3x} 3x`}
                smooth
              />
              <figcaption className="Project-FigureCaption">
                Behind the scenes of a patients encounter in the EHR prototyped in Sketch.
              </figcaption>
            </figure>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Proposed Solutions
            </h3>
            <p className="Project-Paragraph">
              Speaking to customers it was clear CareCloud’s current EHR solution was confusing to navigate
              through creating a disconnect from patient records and the actions users can take. We decided
              to design a unified experience where all of patient’s information could be accessed easily.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Solving the Problems
            </h3>
            <p className="Project-Paragraph">
              Observing current and potential customers interact with the prototypes we found that users
              we’re spending less time having to look for critical clinical information and were able to
              take actions quicker with our simplified navigation and menus.
            </p>
            <Grid>
              <GridCell size="4/8">
                <figure className="Project-Figure" data-aos="zoom-in-up">
                  <Image
                    className="Project-FigureImage"
                    width="420"
                    height="315"
                    src={FigureEncounter1x}
                    srcSet={`${FigureEncounter2x} 2x, ${FigureEncounter3x} 3x`}
                    smooth
                  />
                  <figcaption className="Project-FigureCaption">
                    Timeline View - Perfect for seeing patient progress overtime.
                  </figcaption>
                </figure>
              </GridCell>
              <GridCell size="4/8">
                <figure className="Project-Figure" data-aos="zoom-in-up">
                  <Image
                    className="Project-FigureImage"
                    width="420"
                    height="315"
                    src={FigureSummary1x}
                    srcSet={`${FigureSummary2x} 2x, ${FigureSummary3x} 3x`}
                    smooth
                  />
                  <figcaption className="Project-FigureCaption">
                    Orders FAB - A simplified menu to deliver care to patients
                  </figcaption>
                </figure>
              </GridCell>
            </Grid>
          </section>

          <section className="Project-Section">
            <Grid>
              <GridCell size="4/8">
                <h3 className="Project-Heading">
                  Validation
                </h3>
                <p className="Project-Paragraph">
                  The simplified user interface of the new application is in high-demand. Customers eagerly wait
                  for CareCloud’s next-generation platform to be delivered while prospective customers have been
                  given demo access to provide feedback, they claim increases in productivity.
                </p>
              </GridCell>
              <GridCell size="4/8">
                <blockquote className="Project-Quote">
                  <p className="Project-QuoteText">
                    This is better than anything I&apos;ve ever seen
                  </p>
                  <footer className="Project-QuoteSource">
                    - Physician, 25-provider Medical Group
                  </footer>
                </blockquote>
              </GridCell>
            </Grid>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Challenges Faced
            </h3>
            <p className="Project-Paragraph">
              CareCloud Go is a platform envisioned to satisfy the needs of different personas - it needs to
              be flexible in its functionality but consistent in its look and feel. Medical providers, front
              desk staff and top-level management all have different roles to fulfill in the application, the
              design team had to collaborate and execute a unified vision while working on different areas of
              the application.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Lessons
            </h3>
            <p className="Project-Paragraph">
              While designing the Go EHR, we realized the importance of having users interact with our prototypes,
              parse their feedback and act on the underlying problems we discovered.
            </p>
          </section>
        </div>
      </GridCell>
    </Grid>
  </div>
);

export default Project;
