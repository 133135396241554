import React from 'react';

import 'Components/Icon/Icon.scss';

const Icon = ({
  className = '', svg, width = '', height = '',
}) => {
  const style = {
    ...(width && { width }),
    ...(height && { height }),
  };

  return (
    <span className={`Icon / ${className}`} style={style}>
      {svg}
    </span>
  );
};

export default Icon;
