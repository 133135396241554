import React from 'react';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Image from 'Components/Image/Image';

import 'Pages/Project/Project.scss';

import Splash1x from 'Assets/Images/Beverlly/Splash.png';
import Splash2x from 'Assets/Images/Beverlly/Splash@2x.png';
import Splash3x from 'Assets/Images/Beverlly/Splash@3x.png';

import FigureCompetitor1x from 'Assets/Images/Beverlly/FigureCompetitor.png';
import FigureCompetitor2x from 'Assets/Images/Beverlly/FigureCompetitor@2x.png';
import FigureCompetitor3x from 'Assets/Images/Beverlly/FigureCompetitor@3x.png';
import FigurePricing1x from 'Assets/Images/Beverlly/FigurePricing.png';
import FigurePricing2x from 'Assets/Images/Beverlly/FigurePricing@2x.png';
import FigurePricing3x from 'Assets/Images/Beverlly/FigurePricing@3x.png';

import ToolShopify1x from 'Assets/Images/Common/Shopify.png';
import ToolShopify2x from 'Assets/Images/Common/Shopify@2x.png';
import ToolShopify3x from 'Assets/Images/Common/Shopify@3x.png';
import ToolUserInterviews1x from 'Assets/Images/Beverlly/UserInterviews.png';
import ToolUserInterviews2x from 'Assets/Images/Beverlly/UserInterviews@2x.png';
import ToolUserInterviews3x from 'Assets/Images/Beverlly/UserInterviews@3x.png';
import ToolUserResearch1x from 'Assets/Images/Beverlly/UserResearch.png';
import ToolUserResearch2x from 'Assets/Images/Beverlly/UserResearch@2x.png';
import ToolUserResearch3x from 'Assets/Images/Beverlly/UserResearch@3x.png';
import ToolSurveys1x from 'Assets/Images/Beverlly/Surveys.png';
import ToolSurveys2x from 'Assets/Images/Beverlly/Surveys@2x.png';
import ToolSurveys3x from 'Assets/Images/Beverlly/Surveys@3x.png';

const Project = () => (
  <div className="Project Project--NameBeverlly">
    <Grid>
      <GridCell size="6/8" offset="1/8">
        <div className="Project-Intro">
          <Grid>
            <GridCell size="6/8" offset="1/8">
              <div className="Project-Picture" data-aos="zoom-in-up">
                <Image
                  width="581"
                  height="530"
                  src={Splash1x}
                  srcSet={`${Splash2x} 2x, ${Splash3x} 3x`}
                />
              </div>
            </GridCell>
            <GridCell size="6/8" offset="1/8">
              <h2 className="Project-Title">
                Beverlly.com
              </h2>
              <p className="Project-SubTitle">
                Communicating authenticity in the face of skepticism.
              </p>
            </GridCell>
          </Grid>
        </div>
        <div className="Project-Details">
          <section className="Project-Section">
            <h3 className="Project-Heading">
              About Beverlly.com
            </h3>
            <p className="Project-Paragraph">
              Worldwide Perfumes is a company that has been dedicated to the wholesale of
              fragrances for over 20 years with most of its business generated through more
              traditional channels like telephone calls and emails.
            </p>
            <p className="Project-Paragraph">
              Beverlly.com is the answer to their stagnated growth, an e-commerce website
              designed to capture a whole new customer base.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              The Challenge
            </h3>
            <p className="Project-Paragraph">
              Selling goods to other businesses requires different communication strategies and
              presentation than selling products to their end-users. Our challenge was to create
              a brand-new, mobile-first focused experience, customers could trust, saw value in
              and ultimately returned to.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              My Role
            </h3>
            <p className="Project-Paragraph">
              Alongside a project manager, operation specialist and an outside ad agency I was
              hired as a consultant to guide the overall design execution and communication
              strategy of the project.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Tools Used
            </h3>
            <ul className="Project-Tools">
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="96"
                    height="68"
                    src={ToolUserInterviews1x}
                    srcSet={`${ToolUserInterviews2x} 2x, ${ToolUserInterviews3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    User Interviews
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="73"
                    height="73"
                    src={ToolUserResearch1x}
                    srcSet={`${ToolUserResearch2x} 2x, ${ToolUserResearch3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    User Research
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="62"
                    height="89"
                    src={ToolSurveys1x}
                    srcSet={`${ToolSurveys2x} 2x, ${ToolSurveys3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Surveys
                  </figcaption>
                </figure>
              </li>
              <li className="Project-Tool">
                <figure className="Project-ToolItem" data-aos="zoom-in-up">
                  <Image
                    className="Project-ToolImage"
                    width="74"
                    height="84"
                    src={ToolShopify1x}
                    srcSet={`${ToolShopify2x} 2x, ${ToolShopify3x} 3x`}
                    fluid={false}
                  />
                  <figcaption className="Project-ToolName">
                    Shopify
                  </figcaption>
                </figure>
              </li>
            </ul>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Understanding the Barriers
            </h3>
            <p className="Project-Paragraph">
              Through surveys, we were able to identify 2 key factors that would impact our
              ability to successfully sell to a new audience.
            </p>
            <p className="Project-Paragraph">
              1. Most people were not familiar with the competitors in the space we were about
              to go into and instead shopped for fragrances through traditional retailers like
              Macy’s, Nordstrom and Sephora.
            </p>
            <p className="Project-Paragraph">
              2. Those familiar with the competition felt a sense of uneasiness and avoided shopping
              at these online stores because they feared the products were not authentic.
            </p>

          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Solving the Problems
            </h3>
            <p className="Project-Paragraph">
              Further researching the competition, we realized a lot of the distrust was created by
              the variance in pricing from similar products of the same brand and an abundance of
              prices being advertised to consumers on product pages. People felt overwhelmed by retail
              prices, our low “website name” prices and a myriad of other promotional prices ultimately
              leaving without making a purchase. We decided a simpler approach, to emulate the traditional
              e-commerce experiences consumers were already buying from, only displaying one final price
              always rounded.
            </p>
            <Grid>
              <GridCell size="4/8">
                <figure className="Project-Figure" data-aos="zoom-in-up">
                  <Image
                    className="Project-FigureImage"
                    width="420"
                    height="314"
                    src={FigureCompetitor1x}
                    srcSet={`${FigureCompetitor2x} 2x, ${FigureCompetitor3x} 3x`}
                    smooth
                  />
                  <figcaption className="Project-FigureCaption">
                    A direct competitor’s strategy on displaying prices.
                  </figcaption>
                </figure>
              </GridCell>
              <GridCell size="4/8">
                <figure className="Project-Figure" data-aos="zoom-in-up">
                  <Image
                    className="Project-FigureImage"
                    width="420"
                    height="314"
                    src={FigurePricing1x}
                    srcSet={`${FigurePricing2x} 2x, ${FigurePricing3x} 3x`}
                    smooth
                  />
                  <figcaption className="Project-FigureCaption">
                    Beverlly.com’s no guessing pricing strategy.
                  </figcaption>
                </figure>
              </GridCell>
            </Grid>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Converting the Skeptics
            </h3>
            <p className="Project-Paragraph">
              Upon its launch, in December 2019 Beverlly.com managed to double its expected
              \sales for the holiday season. In its first month of operating over $4,000 worth
              of fragrances were sold to new customers, 20% of which returned to the online store
              for a second time.
            </p>
          </section>

          <section className="Project-Section">
            <h3 className="Project-Heading">
              Lessons Learned
            </h3>
            <p className="Project-Paragraph">
              While price will win out most of the time when dealing with B2B commerce it is important
              to understand people do not operate like businesses. Although the hypothesis from the
              stakeholders that having lower advertised prices would win customers over easily was valid,
              by following a thorough research process of interviews and surveys we quickly found how
              sensitive consumers can be when it comes to pricing.
            </p>
          </section>
        </div>
      </GridCell>
    </Grid>
  </div>
);

export default Project;
