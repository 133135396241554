import React from 'react';

import 'Pages/NotFound/NotFound.scss';

const NotFound = () => (
  <section className="NotFound">
    <h2 className="NotFound-Title">
      Page not found!
    </h2>
  </section>
);

export default NotFound;
