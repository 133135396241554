import React from 'react';

import { toCapitalCase } from 'Utils/general.utils';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Icon from 'Components/Icon/Icon';
import Button from 'Components/Button/Button';
import Image from 'Components/Image/Image';

import { PROJECT_NAME, PROJECT_PATH } from 'Constants/global.constants';

import TypingEffect from 'react-typing-effect';

import 'Pages/Home/Home.scss';

import { ReactComponent as PinIcon } from 'Assets/Icons/Pin.svg';

import GoSplash1x from 'Assets/Images/Go/Splash.png';
import GoSplash2x from 'Assets/Images/Go/Splash@2x.png';
import GoSplash3x from 'Assets/Images/Go/Splash@3x.png';
import TestdSplash1x from 'Assets/Images/Testd/Splash.png';
import TestdSplash2x from 'Assets/Images/Testd/Splash@2x.png';
import TestdSplash3x from 'Assets/Images/Testd/Splash@3x.png';
import BeverllySplash1x from 'Assets/Images/Beverlly/Splash.png';
import BeverllySplash2x from 'Assets/Images/Beverlly/Splash@2x.png';
import BeverllySplash3x from 'Assets/Images/Beverlly/Splash@3x.png';

const Home = () => (
  <div>
    <section className="Cover">
      <div className="Cover-Wrapper">
        <Grid>
          <GridCell size="7/8" offset="1/8">
            <div className="Cover-Text">
              <h2 className="Cover-Title">
                Hi, I’m Fabian.
              </h2>
              <p className="Cover-Flair">
                I Design
                <TypingEffect
                  text={[
                    ' Digital Products',
                    ' User Experiences',
                    ' User Interfaces',
                  ]}
                  speed={75}
                  typingDelay={250}
                  eraseDelay={1000}
                  cursorClassName="Cover-Cursor"
                />
              </p>
              <p className="Cover-Expertise">
                UX | UI Designer
              </p>
              <p className="Cover-Location">
                <Icon className="Cover-Icon" svg={<PinIcon />} />
                Los Angeles, CA
              </p>
            </div>
          </GridCell>
        </Grid>
      </div>
    </section>

    <section className={`Splash Splash--Project${toCapitalCase(PROJECT_NAME.GO)}`}>
      <div className="Splash-Wrapper">
        <Grid center>
          <GridCell size="3/8" offset="1/8">
            <div className="Splash-Text">
              <h2 className="Splash-Title">
                Go EHR
              </h2>
              <p className="Splash-SubTitle">
                CareCloud’s next-generation healthcare platform.
              </p>
              <p className="Splash-Paragraph">
                An Electronic Health Records solution designed from the ground up to help
                physicians spend more time with their patients and less time documenting.
              </p>
              <div className="Splash-Link">
                <Button
                  href={PROJECT_PATH.GO}
                  colorText="#081121"
                >
                  See More
                </Button>
              </div>
            </div>
          </GridCell>
          <GridCell size="4/8">
            <div className="Splash-Picture" data-aos="zoom-in-up">
              <Image
                className="Splash-Image"
                width="581"
                height="530"
                src={GoSplash1x}
                srcSet={`${GoSplash2x} 2x, ${GoSplash3x} 3x`}
              />
            </div>
          </GridCell>
        </Grid>
      </div>
    </section>

    <section className={`Splash Splash--Project${toCapitalCase(PROJECT_NAME.TESTD)}`}>
      <div className="Splash-Wrapper">
        <Grid>
          <GridCell size="4/8" offset="2/8">
            <div className="Splash-Text">
              <h2 className="Splash-Title">
                testd
              </h2>
              <p className="Splash-SubTitle">
                Multi-collaborative Test Case Management Software.
              </p>
              <p className="Splash-Paragraph">
                Introducing collaboration to test case management to empower teams and bring them
                together to deliver quality features.
              </p>
              <div className="Splash-Link">
                <Button
                  href={PROJECT_PATH.TESTD}
                  colorBorder="#f7f9f9"
                >
                  See More
                </Button>
              </div>
            </div>
          </GridCell>
          <GridCell className="Util-MT(64)@M" size="6/8" offset="1/8">
            <div className="Splash-Picture" data-aos="zoom-in-up">
              <Image
                className="Splash-Image"
                width="888"
                height="555"
                src={TestdSplash1x}
                srcSet={`${TestdSplash2x} 2x, ${TestdSplash3x} 3x`}
                smooth
              />
            </div>
          </GridCell>
        </Grid>
      </div>
    </section>

    <section className={`Splash Splash--Project${toCapitalCase(PROJECT_NAME.BEVERLLY)}`}>
      <div className="Splash-Wrapper">
        <Grid center>
          <GridCell size="4/8">
            <div className="Splash-Text">
              <h2 className="Splash-Title">
                Beverlly.com
              </h2>
              <p className="Splash-SubTitle">
                The exciting world of fragrances.
              </p>
              <p className="Splash-Paragraph">
                An e-commerce website selling brand-name fragrances at discounted prices.
              </p>
              <div className="Splash-Link">
                <Button
                  href={PROJECT_PATH.BEVERLLY}
                  colorText="#f7f9f9"
                  colorBorder="#6c8b85"
                >
                  See More
                </Button>
              </div>
            </div>
          </GridCell>
          <GridCell size="4/8">
            <div className="Splash-Picture" data-aos="zoom-in-up">
              <Image
                className="Splash-Image"
                width="580"
                height="580"
                src={BeverllySplash1x}
                srcSet={`${BeverllySplash2x} 2x, ${BeverllySplash3x} 3x`}
              />
            </div>
          </GridCell>
        </Grid>
      </div>
    </section>
  </div>
);

export default Home;
