import React, { useState } from 'react';
import useForm from 'Hooks/Form';

import EmailService from 'emailjs-com';

import { Grid, GridCell } from 'Components/Grid/Grid';
import Icon from 'Components/Icon/Icon';
import Button from 'Components/Button/Button';
import Anchor from 'Components/Anchor/Anchor';

import 'Pages/Contact/Contact.scss';

import { ReactComponent as ErrorIcon } from 'Assets/Icons/Error.svg';
import { ReactComponent as SuccessIcon } from 'Assets/Icons/Success.svg';

const Contact = () => {
  const [isSending, setAsSending] = useState(false);
  const [isSent, setAsSent] = useState(false);
  const [isNotSent, setAsNotSent] = useState(false);

  const stateSchema = {
    FormName: { value: '', error: '' },
    FormEmail: { value: '', error: '' },
    FormSubject: { value: '', error: '' },
    FormMessage: { value: '', error: '' },
  };

  const validationStateSchema = {
    FormName: {
      required: true,
    },
    FormEmail: {
      required: true,
      validator: {
        regEx: /^[\w.-]+@[\w.-]+\.\w{2,}$/,
        error: 'Invalid email format.',
      },
    },
    FormSubject: {
      required: false,
    },
    FormMessage: {
      required: true,
      validator: {
        regEx: /^.{50,}$/,
        error: 'Your message is too short.',
      },
    },
  };

  const onSubmit = (values) => {
    setAsSending(true);

    const data = Object.entries(values).reduce((acc, val) => {
      // eslint-disable-next-line prefer-destructuring
      acc[val[0]] = val[1].value || 'No subject';

      return acc;
    }, {});

    EmailService.send(
      'default_service', 'default_template', data, 'user_Nq6rDckN1l47r3Ql5DeLr',
    )
      .then(
        () => {
          // Success.
          setAsSending(false);
          setAsNotSent(false);
          setAsSent(true);
        },
        () => {
          // Error.
          setAsSending(false);
          setAsSent(false);
          setAsNotSent(true);
        },
      );
  };

  const {
    state, handleChange, handleSubmit, disabled,
  } = useForm(
    stateSchema,
    validationStateSchema,
    onSubmit,
  );

  return (
    <div className="Contact">
      <Grid>
        <GridCell size="6/8" offset="1/8">
          <h2 className="Contact-Title">
            Get in touch.
          </h2>
          <Grid reverse>
            <GridCell size="4/8">
              <dl className="Data">
                <dt className="Data-Title">
                  Phone
                </dt>
                <dd className="Data-Detail">
                  <Anchor
                    className="Data-Link"
                    href="tel:3055105947"
                  >
                    (305) 510 - 5947
                  </Anchor>
                </dd>
                <dt className="Data-Title">
                  Email
                </dt>
                <dd className="Data-Detail">
                  <Anchor
                    className="Data-Link"
                    href="mailto:contact@fabianpombo.com"
                  >
                    contact@fabianpombo.com
                  </Anchor>
                </dd>
                <dt className="Data-Title">
                  LinkedIn
                </dt>
                <dd className="Data-Detail">
                  <Anchor
                    className="Data-Link"
                    href="https://linkedin.com/in/fabian-pombo/"
                    blank
                  >
                    linkedin.com/in/fabian-pombo/
                  </Anchor>
                </dd>
              </dl>
            </GridCell>
            <GridCell size="4/8">
              <form
                id="Form"
                name="Form"
                className="Form"
                action="#"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="Form-Group">
                  <label className="Form-Label" htmlFor="FormName">
                    Name
                  </label>
                  <input
                    className={`Form-Field ${state.FormName.error ? 'Form-Field--Error' : ''}`}
                    id="FormName"
                    name="FormName"
                    type="text"
                    value={state.FormName.value}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {
                    state.FormName.error && (
                      <p className="Form-FieldMessage Form-FieldMessage--Error">
                        {state.FormName.error}
                      </p>
                    )
                  }
                </div>
                <div className="Form-Group">
                  <label className="Form-Label" htmlFor="FormEmail">
                    Email
                  </label>
                  <input
                    className={`Form-Field ${state.FormEmail.error ? 'Form-Field--Error' : ''}`}
                    id="FormEmail"
                    name="FormEmail"
                    type="email"
                    value={state.FormEmail.value}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {
                    state.FormEmail.error && (
                      <p className="Form-FieldMessage Form-FieldMessage--Error">
                        {state.FormEmail.error}
                      </p>
                    )
                  }
                </div>
                <div className="Form-Group">
                  <label className="Form-Label" htmlFor="FormSubject">
                    Subject <span className="Form-Optional">(Optional)</span>
                  </label>
                  <input
                    className="Form-Field"
                    id="FormSubject"
                    name="FormSubject"
                    type="text"
                    value={state.FormSubject.value}
                    onChange={handleChange}
                  />
                </div>
                <div className="Form-Group">
                  <label className="Form-Label" htmlFor="FormMessage">
                    Message
                  </label>
                  <textarea
                    className={`Form-Field Form-Field--TextArea ${state.FormMessage.error ? 'Form-Field--Error' : ''}`}
                    id="FormMessage"
                    name="FormMessage"
                    cols={30}
                    rows={10}
                    value={state.FormMessage.value}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {
                    state.FormMessage.error && (
                      <p className="Form-FieldMessage Form-FieldMessage--Error">
                        {state.FormMessage.error}
                      </p>
                    )
                  }
                </div>
                <div className="Form-Button">
                  <Button
                    disabled={disabled || isSending || isSent}
                    type="submit"
                  >
                    { isSending ? 'Sending…' : 'Send Message' }
                  </Button>
                </div>
                {
                  isSent && (
                    <div className="Form-Message Form-Message--Success">
                      <Icon width="15px" className="Form-MessageIcon" svg={<SuccessIcon />} />
                      Message Sent!
                    </div>
                  )
                }
                {
                  isNotSent && (
                    <div className="Form-Message Form-Message--Error">
                      <Icon width="12px" className="Form-MessageIcon" svg={<ErrorIcon />} />
                      Error. Please try again.
                    </div>
                  )
                }
              </form>
            </GridCell>
          </Grid>
        </GridCell>
      </Grid>
    </div>
  );
};

export default Contact;
