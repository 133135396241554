import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';

import { SECTIONS, PROJECTS } from 'Constants/global.constants';

import Home from 'Pages/Home/Home';
import Work from 'Pages/Work/Work';
import About from 'Pages/About/About';
import Contact from 'Pages/Contact/Contact';
import NotFound from 'Pages/NotFound/NotFound';
import Go from 'Pages/Project/Go';
import Testd from 'Pages/Project/Testd';
import Beverlly from 'Pages/Project/Beverlly';
import Simple from 'Pages/Project/Simple';

import Layout from 'Components/Layout/Layout';

import AOS from 'aos';
import 'aos/dist/aos.css';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Move scrollbar to top.
    window.scrollTo(0, 0);

    // Init AOS.
    AOS.init({
      once: true,
    });
  }, [pathname]);

  return null;
};

const Root = () => {
  const pages = {
    home: Home,
    work: Work,
    about: About,
    contact: Contact,
    go: Go,
    testd: Testd,
    beverlly: Beverlly,
    simple: Simple,
  };

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {
          [...SECTIONS, ...PROJECTS].map((page) => {
            const Section = pages[page.name];

            return (
              <Route key={page.name} exact path={page.path}>
                <Layout
                  page={{
                    element: <Section />,
                    name: page.name,
                  }}
                />
              </Route>
            );
          })
        }
        <Route>
          <Layout
            page={{
              element: <NotFound />,
              name: 'notfound',
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
};

export default Root;
