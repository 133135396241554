import React from 'react';

import 'Components/Anchor/Anchor.scss';

const Anchor = ({
  className = '', href, blank = false, children,
}) => (
  <a
    className={`Anchor / ${className}`}
    href={href}
    target={blank ? '_blank' : null}
    rel={blank ? 'noopener noreferrer' : null}
  >
    {children}
  </a>
);

export default Anchor;
