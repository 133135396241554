import React from 'react';
import Header from 'Components/Header/Header';

import { SECTION_NAME } from 'Constants/global.constants';

import 'Components/Layout/Layout.scss';

const Layout = ({ page: { element, name } }) => (
  <div className={`Layout ${name !== SECTION_NAME.HOME ? 'Layout--Frame' : ''}`}>
    <Header className="Layout-Header" page={name} />
    <main className="Layout-Main">
      {element}
    </main>
  </div>
);

export default Layout;
