const SECTION_NAME = {
  HOME: 'home',
  WORK: 'work',
  PROJECT: 'work',
  ABOUT: 'about',
  CONTACT: 'contact',
};

const SECTION_PATH = {
  HOME: '/',
  WORK: '/work',
  PROJECT: '/project',
  ABOUT: '/about',
  CONTACT: '/contact',
};

const SECTIONS = [
  {
    name: SECTION_NAME.HOME,
    path: SECTION_PATH.HOME,
  },
  {
    name: SECTION_NAME.WORK,
    path: SECTION_PATH.WORK,
  },
  {
    name: SECTION_NAME.PROJECT,
    path: SECTION_PATH.PROJECT,
  },
  {
    name: SECTION_NAME.ABOUT,
    path: SECTION_PATH.ABOUT,
  },
  {
    name: SECTION_NAME.CONTACT,
    path: SECTION_PATH.CONTACT,
  },
];

const PROJECT_NAME = {
  GO: 'go',
  TESTD: 'testd',
  BEVERLLY: 'beverlly',
  SIMPLE: 'simple',
};

const PROJECT_PATH = {
  GO: '/project/go',
  TESTD: '/project/testd',
  BEVERLLY: '/project/beverlly',
  SIMPLE: '/project/simplepractice-challenge',
};

const PROJECTS = [
  {
    name: PROJECT_NAME.GO,
    path: PROJECT_PATH.GO,
  },
  {
    name: PROJECT_NAME.TESTD,
    path: PROJECT_PATH.TESTD,
  },
  {
    name: PROJECT_NAME.BEVERLLY,
    path: PROJECT_PATH.BEVERLLY,
  },
  {
    name: PROJECT_NAME.SIMPLE,
    path: PROJECT_PATH.SIMPLE,
  },
];

export {
  SECTIONS,
  SECTION_NAME,
  SECTION_PATH,
  PROJECTS,
  PROJECT_NAME,
  PROJECT_PATH,
};
